/*------------------------------------------------------------------
[Master Stylesheet]
Theme Name:     Habibi - Wedding & Wedding Planner HTML5 Template
Version:        1.0.0
-------------------------------------------------------------------*/


/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
----------------------------------------------------------------

1. General
	1.1 Theme Reset Style
	1.2 Global Elements

2. header
	2.1 topbar
	2.2 navigation

3. content
	3.1 wpo-hero-slider
	3.2 wpo-couple-section
	3.3 wpo-story-section
	3.4 wpo-portfolio-section
	3.5 wpo-contact-section
	3.6 wpo-event-section
	3.7 wpo-blog-section

4. wpo-footer

5. Home-style-2
	5.1 wpo-couple-section-s2
	5.2 wpo-story-section-s2
	5.3 wpo-team-section
	5.4 wpo-team-section

6. Home-style-3
	6.1 wpo-wedding-date
	6.2 wpo-couple-section-s3
	6.3 wpo-video-section
	6.4 wpo-story-section-s3
	6.5 wpo-contact-section-s3
	6.6 wpo-event-section-s2

7. Home-style-4
	7.1 wpo-partners-section
	7.2 wpo-couple-section-s4
	7.3 wpo-story-section-s4
	7.4 wpo-portfolio-section-s2
	7.5 wpo-team-section
	7.6 wpo-contact-section-s4
	7.7 wpo-event-section-s3

8. Home-style-5
	8.1 wpo-wedding-countdown
	8.2 wpo-couple-section-s5
	8.3 wpo-story-section-s5
	8.4 wpo-contact-section-s5
	8.5 wpo-event-section-s4


9. Home-style-6
	9.1 wpo-wedding-countdown-s2
	9.2 wpo-couple-section-s6
	9.3 wpo-story-section-s6

10. Home-style-7
	10.1 wpo-Service-section
	10.2 wpo-portfolio-section-s3
	10.3 wpo-fun-fact-section
	10.4 wpo-testimonial-section
	10.5 wpo-contact-section-s6
	10.6 wpo-product-section
	10.7 wpo-pricing-section

11. Home-style-8
	11.1 wpo-about-section
	11.2 wpo-service-section-s2
	11.3 wpo-testimonial-section-s2
	11.4 wpo-contact-section-s6
	11.5 wpo-portfolio-section-s4

12. Home-style-9
	12.1 wpo-about-section-s2
	12.2 wpo-video-section-s2
	12.3 wpo-service-section-s3
	12.4 wpo-testimonial-section-s3
	12.5 wpo-offer-section
	12.6 wpo-contact-section-s7

13. wpo-invitation-area

14. wpo-shop-home
    14.1 wpo-about-section
	14.2 wpo-offer-section-s2

15. shop-home-2
	15.1 wpo-category-section
	15.2 wpo-offer-section-s3

16. shop-home-3
	16.1 wpo-category-section-s2
	16.2 wpo-order-section

17. rtl-page

18. wpo-service-single

19. coming-soon-section

20. wpo-cart-page-style

21. wpo-checkout-page-style

22. wpo-shop-page

23. wpo-shop-single-page

24. wpo-team-single

25. wpo-portfolio-single-section

26. wpo-blog-pg-section

27. wpo-blog-single-section

28. wpo-contact-pg-section

29. error-404-section

----------------------------------------------------------------*/


/*------------------------------------------------------------------
1. General
----------------------------------------------------------------*/

// helpers
@import "helpers/variables";
@import "helpers/mixins";

// base
@import "base/base";

@import url("https://use.typekit.net/rrk1zeu.css");

// components
@import "components/section-title";
@import "components/buttons";
@import "components/form";
@import "components/social-links";
@import "components/page-title";
@import "components/preloader";
@import "components/blog-sidebar";
@import "components/shop-sidebar";
@import "components/pagination";

// layout
@import "layout/header";
@import "layout/hero-slider";
@import "layout/footer";

// components
@import "components/sticky-header";


// page
@import "page/home-default";

@import "page/_home-style2";

@import "page/_home-style3";

@import "page/_home-style4";

@import "page/_home-style5";

@import "page/_home-style6";

@import "page/_home-style7";

@import "page/_home-style8";

@import "page/_home-style9";

@import "page/_invitation";

@import "page/_shop-home";

@import "page/_shop-home-2";

@import "page/_shop-home-3";

@import "page/_rtl-home";

@import "page/_service";

@import "page/_coming-soon";

@import "page/_cart";


@import "page/_checkout";

@import "page/_shop";

@import "page/_shop-single";

@import "page/_team-single";

@import "page/_portfolio-single";

@import "page/_blog";

@import "page/_blog-single";

@import "page/_contact";

@import "page/_error-404";

@import "page/_preview";